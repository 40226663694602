



import DocumentEditor from "../components/DocumentEditor.vue";
import Vue from 'vue';
export default Vue.extend({
  name: "document-editor-view",
  components: {
    DocumentEditor,
  },
});
