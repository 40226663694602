


























import Vue from 'vue';
import {mapState, mapActions} from 'vuex';
export default Vue.extend({
  name: "component-list",
  data() {
    return {};
  },
  props: {
    selected: Array,
  },
  mounted(): void {
    this.getComponentSummaries();
  },
  methods: {
    ...mapActions([
      'getComponentSummaries',
    ]),
    addItem(item: any): void {
      this.$emit('add-item', item);
    },
  },
  computed: {
    ...mapState({
      componentSummaries: (state: any) => state.CmsClient.componentSummaries,
      components: (state: any) => state.CmsClient.components,
    }),
  },
});
