




















import Vue from 'vue';
import {mapState, mapActions, mapGetters} from "vuex";
export default Vue.extend({
  name: "google-avatar",
  data() {
    return {};
  },
  watch: {
    ready() {
      setTimeout(() => {
        this.checkSignIn();
      }, 1);
    },
  },
  methods: {
    ...mapActions('googleAuth', [
      'signIn',
      'signOut',
    ]),
    ...mapActions([
      'dismissError',
    ]),
    signOutAndRedirect() {
      this.signOut();
      window.location.reload();
    },
    checkSignIn() {
      if (this.ready && !this.isSignedIn) {
        this.signIn();
      }
    },
  },
  computed: {
    ...mapState({
      idToken: (state: any) => state.googleAuth.idToken,
      signingInOut: (state: any) => state.googleAuth.signingInOut,
      profileData: (state: any) => state.googleAuth.profileData,
      ready: (state: any) => state.googleAuth.ready,
    }),
    ...mapGetters('googleAuth', [
      'isSignedIn',
    ]),
  },
});
