



import DocumentViewer from '../components/DocumentViewer.vue'
import Vue from 'vue';
import {mapActions} from 'vuex';
export default Vue.extend({
  name: 'DocumentViewerView',
  data() {
    return {
      doc: null,
      loading: false,
    };
  },
  async mounted(): Promise<void> {
    this.loading = true;
    // http://funi-cms-service-557-projections.s3-website-us-west-2.amazonaws.com/52973cff-5251-4a38-9ad5-3c79705e1660
    const result = await fetch('http://funi-cms-service-557-projections.s3-website-us-west-2.amazonaws.com/projections/' + this.$route.params.id + '.json');
    const doc = await result.json();
    this.doc = doc;
    await this.loadAll({
      components: this.orderedComponents(),
    });
    this.loading = false;
  },
  methods: {
    ...mapActions([
      'loadAll',
    ]),
    orderedComponents() {
      if (!this.doc) {
        return;
      }
      const comps = JSON.parse(JSON.stringify(this.doc)).components;
      if (!comps) {
        return [];
      }
      return Object.keys(comps).sort((a: string, b: string) => {
        return comps[a].order - comps[b].order;
      }).map((key: string) => {
        return comps[key];
      });
    },
  },
  components: {
    DocumentViewer,
  },
});
