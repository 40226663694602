












































































































































































import Vue from 'vue';
import {mapState, mapActions, mapGetters} from "vuex";
import GoogleAvatar from "./GoogleAvatar.vue";
export default Vue.extend({
  name: "document-list",
  data() {
    return {
      loading: true,
      deleteConfirm: false,
      deleteRef: null as any | null,
      itemsPerPageArray: [8, 16, 64, 128, 256],
      search: "",
      sortDesc: false,
      page: 1,
      itemsPerPage: 8,
      sortBy: "name",
      sortByKeys: [
        "name",
        "version",
        "description",
      ],
    };
  },
  components: {
    GoogleAvatar,
  },
  async mounted() {
    await this.connect();
    this.getToc();
    this.loading = false;
  },
  methods: {
    ...mapActions([
      'connect',
      'getToc',
      'createDocument',
      'archiveDocument',
      'dismissError',
    ]),
    async create() {
      await this.createDocument();
      this.openDocument(this.createdDocumentId);
    },
    confirmDelete(e: any) {
      this.deleteConfirm = true;
      this.deleteRef = e;
    },
    deleteItem() {
      this.deleteConfirm = false;
      this.archiveDocument(this.deleteRef);
    },
    openDocument(id: string) {
      window.open(`/editor/${id}`, "_" + id);
    },
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1;
    },
    updateItemsPerPage(number: number) {
      this.itemsPerPage = number;
    },
  },
  computed: {
    ...mapState({
      createdDocumentId: (state: any) => state.CmsClient.createdDocumentId,
      toc: (state: any) => state.CmsClient.toc,
      error: (state: any) => state.error,
    }),
    ...mapGetters('googleAuth', [
      'isSignedIn',
    ]),
    numberOfPages(): number {
      return Math.ceil(this.tocItems.length / this.itemsPerPage);
    },
    tocItems(): any {
      return Object.keys(this.toc).map((key: string) => {
        return this.toc[key];
      });
    }
  },
});
