
































































































































































import Vue from 'vue';
import {mapState, mapActions, mapGetters} from 'vuex';
import DocumentViewer from './DocumentViewer.vue'
import ComponentList from './ComponentList.vue'
export function newId() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    var r = Math.random() * 16 | 0, v = c == "x" ? r : (r & 0x3 | 0x8); // eslint-disable-line
    return v.toString(16);
  });
}
let debounce: any;
import {diff, applyChange} from "deep-diff";
import GoogleAvatar from "./GoogleAvatar.vue";
export default Vue.extend({
  name: 'document-editor',
  data() {
    return {
      localDoc: {} as any,
      updatingFromRemote: false,
      saving: false,
      loading: false,
      showAddComponent: false,
      selectedComponentId: '',
      selectedNewComponent: {} as any,
      drawerWidth: 270,
    };
  },
  components: {
    DocumentViewer,
    ComponentList,
    GoogleAvatar,
  },
  async mounted(): Promise<void> {
    this.loading = true;
    await this.connect();
    await this.openDocument({
      documentId: this.route.params.id,
    });
    this.localDoc = JSON.parse(JSON.stringify(this.document));
    await this.loadAll();
    this.loading = false;
  },
  watch: {
    document: {
      handler(): void {
        const changes = diff(this.localDoc, JSON.parse(JSON.stringify(this.document)));
        if (changes) {
          this.updatingFromRemote = true;
          changes.forEach((change: any) => {
            applyChange(this.localDoc, true, change);
          });
          this.updatingFromRemote = false;
        }
      },
      deep: true,
    },
    localDoc: {
      handler(): void {
        if (this.updatingFromRemote) {
          return;
        }
        const changes = diff(this.localDoc, JSON.parse(JSON.stringify(this.document)));
        if (changes) {
          clearTimeout(debounce);
          debounce = setTimeout(() => {
            this.save();
          }, 1000);
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions([
      'publish',
      'connect',
      'subscribe',
      'sendToChannel',
      'changeDocument',
      'applyChange',
      'openDocument',
      'getComponentDetails',
    ]),
    publishDoc(): void {
      this.publish({
        documentId: this.localDoc.id,
        targets: [
          's3web'
        ],
      });
    },
    selectComponent(comp: any): void {
      if (this.selectedComponentId === comp.id) {
        this.selectedComponentId = '';
        return;
      }
      this.selectedComponentId = comp.id;
    },
    deleteComponent(comp: any): void {
      delete this.localDoc.components[comp.id];
      let count = 0;
      const comps = Object.keys(this.localDoc.components).map((id: string) => {
        return this.localDoc.components[id];
      }).sort((a: Record<string, any>, b: Record<string, any>) => {
        return a.order - b.order;
      });
      for(const comp of comps) {
        comp.order = count;
        count += 1;
      }
      this.selectedComponentId = '';
      this.save();
    },
    moveUp(comp: any): void {
      if (comp.order === 0) {
        return;
      }
      for(let x = 0; x < this.orderedComponents.length; x += 1) {
        if (this.orderedComponents[x + 1] && this.orderedComponents[x + 1].id === comp.id) {
          this.localDoc.components[this.orderedComponents[x].id].order += 1;
        }
        if (this.orderedComponents[x].id === comp.id) {
          this.localDoc.components[this.orderedComponents[x].id].order -= 1;
        }
      }
      this.save();
    },
    moveDown(comp: any): void {
      if (comp.order === this.orderedComponents.length - 1) {
        return;
      }
      for(let x = 1; x < this.orderedComponents.length; x += 1) {
        if (this.orderedComponents[x - 1] && this.orderedComponents[x - 1].id === comp.id) {
          this.localDoc.components[this.orderedComponents[x].id].order -= 1;
        }
        if (this.orderedComponents[x].id === comp.id) {
          this.localDoc.components[this.orderedComponents[x].id].order += 1;
        }
      }
      this.save();
    },
    getPackage(compDetails: Record<string, any>) {
      const pkgAsset = compDetails.data.assets.find((file: Record<string, any>) => {
        return file.name === 'package/package.json';
      });
      return JSON.parse(atob(pkgAsset.content));
    },
    async addItem(plugin: any): Promise<void> {
      this.showAddComponent = false;
      const compDetails = await this.getComponentDetails({
        pkg: plugin.package,
        version: plugin.latestVersion
      });
      const pkg = this.getPackage(compDetails);
      const id = newId();
      this.$set(this.localDoc.components, id, {
        id,
        pkg: plugin.package,
        version: plugin.latestVersion,
        main: pkg.main.replace(/^\./, 'package/'),
        template: pkg.cms ? pkg.cms.template : plugin.package,
        props: pkg.cms ? pkg.cms.props : {},
        order: this.orderedComponents.length > 0
          ? Math.max.apply(null, this.orderedComponents.map((comp: any) => comp.order)) + 1
          : 0,
      });
      this.selectedComponentId = id;
      this.save();
    },
    async save(): Promise<void> {
      this.saving = true;
      this.changeDocument(this.localDoc);
      await this.applyChange();
      this.saving = false;
    },
    async loadAll(): Promise<void> {
      this.loading = true;
      await Promise.all(this.orderedComponents.map((comp: any) => {
        const src = `https://wzwa9qql31.execute-api.us-west-2.amazonaws.com/557/v1/codeArtifact/pkg/${comp.pkg}/${comp.version}/package%2F${encodeURIComponent(comp.main)}`;
        console.log(src);
        return this.loadScript(src);
      }));
      const setup = (window as any).CompSampleAdminPanel.default;
      const install = await setup({}, this.$store);
      (this.$root as any).vue.$store = this.$store;
      (this.$root as any).vue.use(install);
      this.loading = false;
    },
    loadScript(src: string): Promise<void> {
      return new Promise((resolve: any, reject: any) => {
        const doc: Document = document;
        const script: HTMLScriptElement = doc.createElement('script');
        (script as any).module = true;
        script.async = false;
        script.defer = true;
        script.onload = resolve;
        script.onerror = reject;
        script.src = src;
        doc.body.appendChild(script);
      });
    }
  },
  computed: {
    ...mapGetters([
      'document',
      'orderedComponents',
    ]),
    selectionOverlay(): Record<string, any> {
      const el = window.document.getElementById(`comp${this.selectedComponentId}`);
      const s = !!this.selectedComponentId && el;
      let rect = {} as Record<string ,any>;
      if (s) {
        rect = el!.getBoundingClientRect(); // eslint-disable-line
      }
      return {
        pointerEvents: 'none',
        opacity: s ? '1' : '0',
        position: 'absolute',
        top: rect.top + 'px',
        left: (rect.left - this.drawerWidth) + 'px',
        height: rect.height + 'px',
        width: rect.width + 'px',
        border: '1px dashed yellow',
        zIndex: '2',
        transition: 'all 0.2s',
      };
    },
    selectedOrderedComponents(): Record<string, any>[] {
      return this.orderedComponents.filter((comp: any) => {
        return comp.id === this.selectedComponentId;
      });
    },
    ...mapState({
      route: (state: any) => state.route,
    }),
  },
});
