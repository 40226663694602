



import DocumentList from "../components/DocumentList.vue";
import Vue from 'vue';
export default Vue.extend({
  name: "document-list-view",
  data() {
    return {};
  },
  components: {
    DocumentList,
  },
  computed: {},
});
