















import Vue from 'vue';
export default Vue.extend({
  name: "document-viewer",
  data() {
    return {
      loading: false,
    };
  },
  props: {
    components: Array,
  },
  computed: {},
});
